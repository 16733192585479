import React,{useEffect,useState} from "react";
import TitleComponent from "./TitleComponent";
import ModalComponent from "./modal";
import Container from "./Container";
import { css } from "@emotion/react";
import { HiDownload } from "react-icons/hi";
import { StaticImage } from "gatsby-plugin-image";

const DenseTable = () => {
  const [State, setState] = useState(0);

  useEffect(() => {
    setState(window.innerWidth);
  }, [State]);

  return (
    <Container>
      <TitleComponent title="Price" size="3vw" color="#7a8c57" />
      <br />
      <div className="tab-content" css={abcd}>
        <div></div>
        <div>
          <br />
          <table style={{ borderSpacing: "0px" }}>
            <tr>
              <th>Type</th>
              <th>Area</th>
             {State > 600 ? <><th>
                <HiDownload style={{ fontSize: "13px" }} /> Price
              </th></>: ""}
              <th>
                <HiDownload style={{ fontSize: "13px" }} /> Cost sheet
              </th>
            </tr>
            <tr>
              <td>1 BHK</td>
              <td><ModalComponent title="On Request" class="prestigecopyabcde" /></td>
              {State > 600 ? <><td>
                <ModalComponent title="On Request" class="prestigecopyabcde" />
              </td></>: ""}
              <td>
                <ModalComponent
                  title="Price Breakup"
                  class="prestigecopyabcde"
                />
              </td>
            </tr>
            <tr>
              <td>2 BHK</td>
              <td><ModalComponent title="On Request" class="prestigecopyabcde" /></td>
              {State > 600 ? <><td>
                <ModalComponent title="On Request" class="prestigecopyabcde" />
              </td></> : ""}
              <td>
                <ModalComponent
                  title="Price Breakup"
                  class="prestigecopyabcde"
                />
              </td>
            </tr>
            <tr>
              <td>3 BHK</td>
              <td><ModalComponent title="On Request" class="prestigecopyabcde" /></td>
              {State > 600 ? <><td>
                <ModalComponent title="On Request" class="prestigecopyabcde" />
              </td></> : ""}
              <td>
                <ModalComponent
                  title= "Price Breakup"
                  class="prestigecopyabcde"
                />
              </td>
            </tr>
           
          
          </table>
        </div>
        <div></div>
      </div>
      <br />
      <div css={last}>
        <br />
        <div>
          <TitleComponent title="Cost Sheet" size="3vw" color="#7a8c57" />
          <br />
          <div className="OverlayContainer">
           {State > 600 ?  <StaticImage
              src="../images/costing-details.jpg"
              alt="Adarsh Group Costing"
              placeholder="blurred"
              layout="fixed"
              height={255}/>: <StaticImage
              src="../images/costing-details.jpg"
              alt="Adarsh Group Costing"
              placeholder="blurred"
              layout="fixed"
              height={180}/> } 
           
            <div className="overlay">
              <></>
            </div>
            <div className="overlay1">
              <ModalComponent title="Check Availability" class="overlayfinal" />
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <br />
    </Container>
  );
};

export default DenseTable;

const abcd = css`
  display: grid;
  grid-template-columns: 2fr 6fr;
  @media (max-width: 600px) {
    display: block;

  }
`;

const last = css`
  display: grid;
  grid-template-columns: 4fr 4fr 4fr;
  text-align: -webkit-center;
  @media (max-width: 600px) {
    display: block;
    margin-top: -20px;
    text-align: -webkit-center;
  }
`;
